import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import Web3 from 'web3';
import ghost from '../../assets/ghost-short.svg'
import devil from '../../assets/devil-short.svg'


const web3 = new Web3(Web3.givenProvider)
  //  get user account



const CardImage = styled.img`
  margin-bottom: 16px;
`
const baseUrl = process.env.REACT_APP_BASE_URL





const Referrals = () => {

	const[string,setString] = useState(null);

	const getUserAccount = async () => {
		if(Web3.givenProvider) {
			const accounts = await web3.eth.getAccounts();
			setString(`${baseUrl}${accounts[0]}`);
			console.log(`userAccount======${accounts[0]}`)
		}
		else{
			setString('Please connect your wallet to get your link');
		}
		
	}
	
	useEffect(() => {
		getUserAccount()
	  }, []);


    return(
		<div >
        <div className="ref-card-box" style={{marginRight:'30px', marginLeft:'30px', marginTop:'10%', marginBottom:'30%'}}>
			<div className="nightmare-image-box" >
				<img src={ghost} alt="Nightmare" style={{width:"100%", height:"auto"}}/>
			</div>
			<div className="card-text-box">
				<p style={{fontSize:'16px', color:"white"}}>
				Earn 1.5% of your nightmare friends earnings! Use this link to invite friends.
				</p>
				<p style={{fontSize:'16px',  color:"white"}}>
				{string}
				</p>
			</div>
			<div className="nightmare-image-box" >
				<img src={devil} alt="Nightmare" style={{width:"100%"}}/>
			</div>
		</div>
		</div>
    );
} 

export default Referrals;