
export default {
  referrals:{
    97: '0x23cF3FB108c8A541560be9f4ab628336FD43DE61',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    80001:'0x61E4cf89afD050147FED769510a7245BBA73b2cA'
  },
  masterChef: {
    97: '0xEde33178fb817017f15166d5eB650fA2A484DCa6',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    80001:'0x61E4cf89afD050147FED769510a7245BBA73b2cA'
    // 80001: '0xaB4Ea59EE4d6B318EeF0fBA0f968AFcA77f0fda2',
  },
  sousChef: {
    97: '0xBc53311FfD08405Da3b7f6A9cf2446D62fff5c26',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
    80001: '0xEdAed04d9A00c382796663E4c0c88D4a6C9BC6bD'
  },
  lottery: {
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
    80001: '0x2EAe8FaeddDb6644d832514D61CD195bB7E5B7C7'
  },
  lotteryNFT: {
    97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
    80001: '0xF4DAEa31F8D73a56D0d9C24B2001fafE2753389a'
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x726517a9e6A85d0f78290192AcC50158cfB68402',
    80001: '0xA2c157f6E49C744Da021E8f09FA4f87229F7fd8c'
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    80001:  '0xc9595c312b0EFAdc8dfc7A5e5E2cE075B00AF298'
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    80001:  '0x7ce2f94C1Bd46171c02A9E645bf574c466c516dC'
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    80001:  '0x2b0d9E8C7312D253fBA8a61680F1087265d8a329'
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
    80001:  '0x24522A6E3d8Fb6A096174eBfbDc41215335D0c2b'
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    80001:  '0x7bD908CD7194AE1407099165245291AE11eDc41f'
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    80001:  '0xb3b2B8de193B67c8854eb48990fe11B5f8Eb2206'
  },
}
